import React, { useState } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import { STATES } from "../../../constants";
import Checkbox from "../../../components/-common/Checkbox";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import useOnEnterPressed from "../../../hooks/useOnEnterPressed";
import AuthStore from "../../../stores/AuthStore";
import { Input, Spinner, Button, Select, SecureInput } from "../../../components/-common";
import "./SignUp.scss";

const howDidYouHearAboutUsOptions = ["Friend or family member", "Google search", "Facebook", "Instagram"];

const SignUp = observer(() => {
  const { isMobile } = useBreakpoints();

  const [firstName, setFirstName] = useState("");
  const updateFirstName = ({ target }) => setFirstName(target.value);

  const [lastName, setLastName] = useState("");
  const updateLastName = ({ target }) => setLastName(target.value);

  const [email, setEmail] = useState("");
  const updateEmail = ({ target }) => setEmail(target.value);

  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const updateAgreeToTerms = ([newValue]) => setAgreeToTerms(newValue);

  const [phone, setPhone] = useState("");
  const updatePhone = ({ target }) => {
    if (!isNaN(Number(phone.slice(-1)))) {
      const phoneFormatter = new AsYouType("US");
      const phone = phoneFormatter.input(target.value);
      setPhone(phone);
    } else {
      setPhone(target.value);
    }
  };

  const [address1, setAddress1] = useState("");
  const updateAddress1 = ({ target }) => setAddress1(target.value);

  const [address2, setAddress2] = useState("");
  const updateAddress2 = ({ target }) => setAddress2(target.value);

  const [city, setCity] = useState("");
  const updateCity = ({ target }) => setCity(target.value);

  const [state, setState] = useState();

  const [zip, setZip] = useState("");
  const updateZip = ({ target }) => setZip(target.value?.slice(0, 5));

  const [password, setPassword] = useState("");
  const updatePassword = ({ target }) => setPassword(target.value);

  const [confirmPassword, setConfirmPassword] = useState("");
  const updateConfirmPassword = ({ target }) => setConfirmPassword(target.value);

  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState();

  const [errorMessage, setErrorMessage] = useState();

  let phoneIsValid = false;
  try {
    phoneIsValid = parsePhoneNumber(phone, "US").isValid();
  } catch {}
  const signUpEnabled =
    !!firstName &&
    !!lastName &&
    !!email &&
    phoneIsValid &&
    !!address1 &&
    !!city &&
    !!state &&
    !!zip &&
    !!password &&
    password === confirmPassword &&
    howDidYouHearAboutUs &&
    agreeToTerms;
  const attemptSignUp = async () => {
    if (!signUpEnabled) return;
    setErrorMessage("");

    const formattedPhone = parsePhoneNumber(phone, "US").format("E.164");
    const { success, error } = await AuthStore.signUp({
      firstName,
      lastName,
      email,
      phone: formattedPhone,
      address1,
      address2,
      city,
      state,
      zip,
      password,
      howDidYouHearAboutUs
    });
    if (success) {
      navigate("/auth/verify", { state: { email } });
    } else {
      setErrorMessage(error);
    }
  };
  useOnEnterPressed(attemptSignUp);

  const goToLogIn = () => navigate("/auth");

  const alreadyHaveAnAccountSection = (
    <div className="already-have-an-account">
      <p className="helper-text" />
      <p className="helper-text">Already have an account?</p>
      <div className="buttons-row">
        <div className="cta-button-wrapper">
          <Button className="go-to-login-button" type="cta" action={goToLogIn}>
            Go To Log In
          </Button>
        </div>
      </div>
    </div>
  );

  const buttonContents = AuthStore.loading ? <Spinner /> : "Sign Up";
  const buttonStyles = AuthStore.loading ? { opacity: 0.7, pointerEvents: "none" } : {};

  const errorComponent = errorMessage ? (
    <div className="error-container">
      <div className="error-text">{errorMessage}</div>
    </div>
  ) : null;

  const phoneInput = (
    <div className="inputs-row-section">
      <div className="inputs-row-title">Phone Number</div>
      <Input type="tel" placeholder="(555) 123-4567" value={phone} onChange={updatePhone} autoComplete="tel" />
    </div>
  );
  const phoneInputStandaloneRow = isMobile ? <div className="inputs-row">{phoneInput}</div> : null;
  const cityInput = (
    <Input type="text" placeholder="City" value={city} onChange={updateCity} autoComplete="address-level-2" />
  );
  const cityInputStandaloneRow = isMobile ? <div className="inputs-row group">{cityInput}</div> : null;

  return (
    <div className="sign-up">
      <div className="left-col">
        <h1 className="hero-text">Let's get some information.</h1>
        <p className="helper-text">
          After this, we'll send you an email with a confirmation code to make sure we can send your receipt and
          communicate with you in the future.
        </p>
        <p className="helper-text">
          Your teacher will also use this email address to communicate with you, so make sure it's one you actually use!
        </p>
        {isMobile ? null : alreadyHaveAnAccountSection}
      </div>
      <div className="right-col">
        <div className="sign-up-content">
          <div className="sign-up-square">
            <div className="sign-up-inputs">
              <div className="inputs-row-title">Name</div>
              <div className="inputs-row">
                <Input
                  type="text"
                  placeholder="Jane"
                  value={firstName}
                  onChange={updateFirstName}
                  autoComplete="given-name"
                />
                <Input
                  type="text"
                  placeholder="Doe"
                  value={lastName}
                  onChange={updateLastName}
                  autoComplete="family-name"
                />
              </div>
              <div className="inputs-row">
                <div className="inputs-row-section">
                  <div className="inputs-row-title">Email Address</div>
                  <Input
                    type="email"
                    placeholder="janedoe1@gmail.com"
                    value={email}
                    onChange={updateEmail}
                    autoComplete="email"
                  />
                </div>
                {isMobile ? null : phoneInput}
              </div>
              {phoneInputStandaloneRow}
              <div className="inputs-row-title">Address</div>
              <div className="inputs-row group">
                <Input
                  type="text"
                  placeholder="Address Line 1"
                  value={address1}
                  onChange={updateAddress1}
                  autoComplete="address-line1"
                />
              </div>
              <div className="inputs-row group">
                <Input
                  type="text"
                  placeholder="Address Line 2  (optional)"
                  value={address2}
                  onChange={updateAddress2}
                  autoComplete="address-line1"
                />
              </div>
              {cityInputStandaloneRow}
              <div className="inputs-row">
                {isMobile ? null : cityInput}
                <Select
                  placeholder="State"
                  options={STATES}
                  value={state}
                  onChange={setState}
                  autoComplete="address-level1"
                />
                <Input
                  type="text"
                  placeholder="ZIP Code"
                  value={zip}
                  onChange={updateZip}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  autoComplete="postal-code"
                />
              </div>
              <div className="inputs-row-title">How did you hear about us?</div>
              <div className="inputs-group">
                <Select
                  placeholder="Select..."
                  options={howDidYouHearAboutUsOptions}
                  value={howDidYouHearAboutUs}
                  onChange={setHowDidYouHearAboutUs}
                />
              </div>
              <div className="inputs-row-title">Create a Password</div>
              <div className="inputs-row-subtitle">Your password should have at least six characters.</div>
              <div className="inputs-group">
                <SecureInput
                  type="password"
                  placeholder="$ecr3tP4ssw0rd"
                  value={password}
                  onChange={updatePassword}
                  autoComplete="new-password"
                />
                <SecureInput
                  type="password"
                  placeholder="Enter one more time to confirm"
                  value={confirmPassword}
                  onChange={updateConfirmPassword}
                  autoComplete="new-password"
                />
                {errorComponent}
              </div>
            </div>
            <div className="checkbox-row">
              <Checkbox
                value="I agree to the terms and conditions"
                selected={agreeToTerms}
                onChange={updateAgreeToTerms}
              />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.letsplaymusicsite.com/enrollmentdisclaimer?srsltid=AfmBOooiymXH63uT7SQTJ1GmH3MCJIMlre66Q9-fOwRyJsAn-0iewMre"
                className="link"
              >
                View the terms here.
              </a>
            </div>
            <Button
              type="cta"
              className="log-in-button"
              style={buttonStyles}
              action={attemptSignUp}
              disabled={!signUpEnabled}
            >
              {buttonContents}
            </Button>
          </div>
        </div>
        {isMobile ? alreadyHaveAnAccountSection : null}
      </div>
    </div>
  );
});

export default SignUp;
